import React from "react";
import { WebcamStreamState, WebcamSnapshot } from "../types";
import { useWebcamStream } from "../hooks";
import { GoogleStorageImage } from "./GoogleStorageImage";

const COUNTDOWN_TIME = 3; // seconds
export interface WebcamDisplayStatic {
  swapCamera(): void;
  createSnapshot(): Promise<WebcamSnapshot | undefined>;
}

export interface WebcamDisplayProps {
  overlayImage?: string | null;
}

export const WebcamDisplay = React.memo(
  React.forwardRef<WebcamDisplayStatic, WebcamDisplayProps>(
    ({ overlayImage }: React.PropsWithChildren<WebcamDisplayProps>, ownRef) => {
      const videoRef = React.createRef<HTMLVideoElement>();
      const { stream, createSnapshot, swapCamera } = useWebcamStream();
      const [countdown, setCountdown] = React.useState<number>();

      // Attach the video stream whenever it or the video ref changes
      React.useEffect(() => {
        if (!videoRef.current) {
          return;
        }

        if (stream.state === WebcamStreamState.Active) {
          if (videoRef.current.srcObject !== stream.stream) {
            videoRef.current.srcObject = stream.stream;
          }
        } else {
          videoRef.current.srcObject = null;
        }
      }, [stream, videoRef]);

      // Imperative method for capturing a snapshot
      React.useImperativeHandle(ownRef, () => ({
        swapCamera,
        createSnapshot: async () => {
          if (!videoRef.current || typeof countdown === "number") {
            return undefined;
          }
          const videoEl = videoRef.current;

          let countdownTimeToSet = COUNTDOWN_TIME;
          while (countdownTimeToSet > 0) {
            setCountdown(countdownTimeToSet);
            await new Promise((resolve) => setTimeout(resolve, 1000));
            countdownTimeToSet -= 1;
          }

          setCountdown(undefined);
          return createSnapshot(videoEl);
        },
      }));

      const isUserFacing =
        stream.state === WebcamStreamState.Active && stream.userFacing;

      // Render everything
      return (
        <div
          className={`aspectContainer webcamPreviewContainer${
            isUserFacing ? " userFacing" : ""
          }`}
        >
          <video ref={videoRef} autoPlay muted playsInline controls={false} />

          {overlayImage && (
            <div id="captureSourceImage">
              <GoogleStorageImage src={overlayImage} />
            </div>
          )}

          {typeof countdown === "number" && (
            <div id="countdownOverlay">
              <p>{countdown}</p>
            </div>
          )}
        </div>
      );
    }
  )
);
