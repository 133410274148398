import React from "react";
import { WebcamDisplay, WebcamDisplayStatic } from "../../../components";
import { WebcamSnapshot } from "../../../types";
import captureImage from "../../../images/capture.svg";
import swapCameraImage from "../../../images/swap_camera.svg";

export interface CaptureSnapshotProps {
  sourceImage: string | null;
  onCapture: (snapshot: WebcamSnapshot) => void;
}

export const CaptureSnapshot: React.FC<CaptureSnapshotProps> = ({
  sourceImage,
  onCapture,
}) => {
  const webcamDisplayRef = React.createRef<WebcamDisplayStatic>();

  const doCapture = React.useCallback(async () => {
    const snapshot = await webcamDisplayRef.current?.createSnapshot();

    if (snapshot) {
      onCapture(snapshot);
    }
  }, [onCapture, webcamDisplayRef]);

  const swapCamera = React.useCallback(() => {
    webcamDisplayRef.current?.swapCamera();
  }, [webcamDisplayRef]);

  return (
    <>
      <WebcamDisplay ref={webcamDisplayRef} overlayImage={sourceImage} />

      <div className="captureButtons">
        <div className="left" />
        <div className="center">
          <button type="button" onClick={doCapture}>
            <img src={captureImage} alt="Capture" />
          </button>
        </div>
        <div className="right">
          <button type="button" onClick={swapCamera}>
            <img src={swapCameraImage} alt="Swap camera" />
          </button>
        </div>
      </div>
    </>
  );
};
