import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../hooks";

export const Logout = () => {
  const { isSignedIn, performSignOut } = useAuth();

  React.useEffect(() => {
    performSignOut();
  }, [performSignOut]);

  if (!isSignedIn) {
    return <Redirect to="/" />;
  } else {
    return null;
  }
};
