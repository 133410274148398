import React from "react";
import retryPromise from "promise-retry";
import { firebase } from "../data/firebase";

const cache: { [key: string]: string } = {};

export const useGoogleStorageUrl = (
  src: string | null | undefined
): string | undefined => {
  const [externalSrc, setExternalSrc] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (src?.startsWith("gs://")) {
      if (typeof cache[src] === "string") {
        setExternalSrc(cache[src]);
        return;
      }

      retryPromise(
        (retry) =>
          firebase
            .storage()
            .refFromURL(src)
            .getDownloadURL()
            .then((newSrc) => {
              cache[src] = newSrc;
              setExternalSrc(newSrc);
            })
            .catch(retry),
        {
          retries: 5,
        }
      ).catch((e) => {
        console.warn(`Error loading Google Storage URL ${src}`, e);
        setExternalSrc(undefined);
      });
    } else {
      setExternalSrc(src || undefined);
    }
  }, [src]);

  return externalSrc;
};
