import React from "react";
import { SnapshotDisplay } from "../../../components";
import { WebcamSnapshot } from "../../../types";
import rejectImage from "../../../images/reject.svg";
import acceptImage from "../../../images/accept.svg";

export interface ReviewSnapshotProps {
  snapshot: WebcamSnapshot;
  onAccept: () => void;
  onReject: () => void;
}

export const ReviewSnapshot: React.FC<ReviewSnapshotProps> = ({
  onAccept,
  onReject,
  snapshot,
}) => (
  <>
    <SnapshotDisplay snapshot={snapshot} />
    <div className="captureButtons">
      <div className="left">
        <button type="button" onClick={onReject}>
          <img src={rejectImage} alt="Reject snapshot" />
        </button>
      </div>
      <div className="center" />
      <div className="right">
        <button type="button" onClick={onAccept}>
          <img src={acceptImage} alt="Accept snapshot" />
        </button>
      </div>
    </div>
  </>
);
