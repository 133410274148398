import React from "react";
import { WebcamStream, WebcamStreamState, WebcamSnapshot } from "../../types";

export const WebcamStreamContext = React.createContext<{
  stream: WebcamStream;
  startStream: () => void;
  stopStream: () => void;
  swapCamera: () => void;
  createSnapshot: (
    videoEl: HTMLVideoElement
  ) => Promise<WebcamSnapshot | undefined>;
}>({
  stream: { state: WebcamStreamState.Inactive },
  startStream: () => {
    throw new Error("Must be used inside a WebcamStreamProvider");
  },
  stopStream: () => {
    throw new Error("Must be used inside a WebcamStreamProvider");
  },
  swapCamera: () => {
    throw new Error("Must be used inside a WebcamStreamProvider");
  },
  createSnapshot: () => {
    throw new Error("Must be used inside a WebcamStreamProvider");
  },
});
