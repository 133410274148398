import React, { FormEvent } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { updateSequence } from "../../data";
import { useFormInput, useSequence, useSequenceIdParam } from "../../hooks";

export const AdminSequenceTiming = () => {
  const history = useHistory();
  const alert = useAlert();

  const sequenceId = useSequenceIdParam();
  const sequence = useSequence(sequenceId);

  const [
    timingSourceImage,
    onTmingSourceImageChange,
    setTimingSourceImage,
  ] = useFormInput();
  const [timingImages, onTimingImagesChange, setTimingImages] = useFormInput();
  React.useEffect(() => {
    if (!sequence) {
      return;
    }

    setTimingSourceImage(sequence.timingSourceImage.toString());
    setTimingImages(sequence.timingImages.toString());
  }, [sequence, setTimingImages, setTimingSourceImage]);

  const [isUpdating, setIsUpdating] = React.useState(false);
  const onSubmit = React.useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (!sequence) {
        return;
      }

      const timingSourceImageNumber = parseInt(timingSourceImage, 10);
      const timingImagesNumber = parseInt(timingImages, 10);
      if (
        Number.isNaN(timingImagesNumber) ||
        Number.isNaN(timingImagesNumber)
      ) {
        alert.error("You did not enter integer numbers");
        return;
      }

      setIsUpdating(true);

      try {
        await updateSequence({
          ...sequence,
          timingSourceImage: timingSourceImageNumber,
          timingImages: timingImagesNumber,
        });

        alert.success("Updated video timing");
        history.push(`/admin/sequences/${sequenceId}`);
      } catch (e) {
        console.error(e);
        alert.error(`Error while updating video timing ${e.toString()}`);
      }

      setIsUpdating(false);
    },
    [alert, history, sequence, sequenceId, timingImages, timingSourceImage]
  );

  if (sequence === undefined) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <h1>Edit video timing</h1>

      <form onSubmit={onSubmit}>
        <div className="formGroup">
          <label htmlFor="timingSourceImage">Source image time (ms)</label>
          <input
            id="timingSourceImage"
            type="number"
            value={timingSourceImage}
            onChange={onTmingSourceImageChange}
          />
        </div>

        <div className="formGroup">
          <label htmlFor="timingImages">Image time (ms)</label>
          <input
            name="timingImages"
            type="number"
            value={timingImages}
            onChange={onTimingImagesChange}
          />
        </div>

        <button type="submit" disabled={isUpdating}>
          {isUpdating ? "Updating..." : "Update"}
        </button>
      </form>
    </>
  );
};
