import firebase from "firebase";
import _ from "lodash";

export type SequenceImageId = string;
export interface SequenceImage {
  id: SequenceImageId;
  location: string;
  created: number; // unix timestamp
}

export const sequenceImageConvertor: firebase.firestore.FirestoreDataConverter<SequenceImage> = {
  toFirestore: (modelObject: SequenceImage) => _.omit(modelObject, "id"),

  fromFirestore: (snapshot) => ({
    id: snapshot.id,
    location: snapshot.get("location"),
    created: snapshot.get("created"),
  }),
};
