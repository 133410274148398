import { Link } from "react-router-dom";
import { useSequences } from "../../hooks";

export const AdminListSequences = () => {
  const sequences = useSequences();
  return (
    <>
      <h1>Sequences</h1>
      {sequences === undefined ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {sequences?.map((sequence) => (
            <li key={sequence.id}>
              <Link to={`/admin/sequences/${sequence.id}`}>
                {sequence.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
