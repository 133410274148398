import React, { VideoHTMLAttributes } from "react";
import { useGoogleStorageUrl } from "../hooks";

export const GoogleStorageVideo: React.FunctionComponent<
  VideoHTMLAttributes<HTMLVideoElement>
> = ({ src, onClick, ...rest }) => {
  const videoRef = React.createRef<HTMLVideoElement>();

  const handleOnClick = React.useCallback(
    (event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
      videoRef.current?.play();
      onClick?.(event);
    },
    [onClick, videoRef]
  );

  const internalSrc = useGoogleStorageUrl(src);
  return internalSrc ? (
    <video
      ref={videoRef}
      src={internalSrc}
      onClick={handleOnClick}
      autoPlay
      playsInline
      {...rest}
    />
  ) : null;
};
