import React from "react";
import { firebase } from "../data/firebase";
import { Sequence, sequenceConvertor, SequenceId } from "../types";

export const useSequence = (
  sequenceId: SequenceId
): Sequence | null | undefined => {
  const [sequence, setSequence] = React.useState<Sequence | null | undefined>();

  React.useEffect(
    () =>
      firebase
        .firestore()
        .collection("sequences")
        .doc(sequenceId)
        .withConverter(sequenceConvertor)
        .onSnapshot((snapshot) => {
          if (!snapshot.exists) {
            setSequence(null);
          } else {
            setSequence(snapshot.data());
          }
        }),
    [sequenceId]
  );

  return sequence;
};
