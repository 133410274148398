import React from "react";
import { useAlert } from "react-alert";

export const useFormFileInput = ({
  mimeTypePrefix,
}: {
  mimeTypePrefix?: string;
} = {}): [
  File | undefined,
  (event: React.ChangeEvent<HTMLInputElement>) => void,
  (file: File | undefined) => void
] => {
  const alert = useAlert();
  const [file, setFile] = React.useState<File>();

  const handleFileChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      if (event.target.files?.length !== 1) {
        return;
      }

      const eventFile = event.target.files[0];
      if (mimeTypePrefix && !eventFile.type.startsWith(mimeTypePrefix)) {
        alert.error("Pleae only choose images");
        // eslint-disable-next-line no-param-reassign
        event.target.value = "";
        return;
      }

      setFile(event.target.files[0]);
    },
    [alert, mimeTypePrefix]
  );

  return [file, handleFileChange, setFile];
};
