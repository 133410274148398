import React, { FormEvent } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { updateSequence } from "../../data";
import { useFormInput } from "../../hooks";

export const CreateSequence = () => {
  const history = useHistory();
  const alert = useAlert();

  const [sequenceId, onSequenceIdChange] = useFormInput();
  const [sequenceName, onSequenceNameChange] = useFormInput();
  const [description, onDescriptionChange] = useFormInput();

  const [isCreating, setIsCreating] = React.useState(false);
  const onSubmit = React.useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (sequenceId.length < 4 || !sequenceId.match(/^[a-z0-9-]*/i)) {
        alert.error(
          "Invalid sequence id. Must only include letters, numbers, and hyphens (-)"
        );
        return;
      }

      if (sequenceName.length < 4) {
        alert.error("Invalid sequence name. Must be at least 3 characters");
        return;
      }

      setIsCreating(true);

      try {
        await updateSequence({
          id: sequenceId,
          name: sequenceName,
          description,
          logo: null,
          logoHeight: 50,
          backgroundColor: "#FFFFFF",
          textColor: "#000000",
          sourceImage: null,
          overlayImage: null,
          video: null,
          videoCreated: null,
          applyOverlayInVideo: true,
          hideSourceImageInVideo: true,
          timingSourceImage: 1000,
          timingImages: 250,
        });

        history.push(`/admin/sequences/${sequenceId}`);
      } catch (e) {
        console.error(e);
        alert.error(`Error while creating sequence ${e.toString()}`);
      }

      setIsCreating(false);
    },
    [alert, description, history, sequenceId, sequenceName]
  );

  return (
    <>
      <h1>Create sequence</h1>

      <form onSubmit={onSubmit}>
        <div className="formGroup">
          <label htmlFor="sequenceId">Id</label>
          <input
            id="sequenceId"
            value={sequenceId}
            onChange={onSequenceIdChange}
          />
        </div>

        <div className="formGroup">
          <label htmlFor="sequenceName">Name</label>
          <input
            name="sequenceName"
            value={sequenceName}
            onChange={onSequenceNameChange}
          />
        </div>

        <div className="formGroup">
          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            value={description}
            onChange={onDescriptionChange}
          />
        </div>

        <button type="submit" disabled={isCreating}>
          {isCreating ? "Creating..." : "Create"}
        </button>
      </form>
    </>
  );
};
