import { useSequenceImages } from "./useSequenceImages";
import { SequenceId } from "../types";

export const useSequenceImagesLastCreated = (
  sequeneId: SequenceId
): number | null | undefined => {
  const sequenceImages = useSequenceImages(sequeneId);
  const lastCreated = sequenceImages?.reduce(
    (prev: number | null, sequenceImage) =>
      prev === null || sequenceImage.created > prev
        ? sequenceImage.created
        : prev,
    null
  );
  return lastCreated;
};
