import { Footer } from "../components/Footer";
import logo from "../images/logo.svg";

export const Home = () => (
  <div id="homepage">
    <h1>
      <img src={logo} alt="Copy Pics" />
    </h1>

    <Footer />
  </div>
);
