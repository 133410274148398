import React, { FormEvent } from "react";
import { useAlert } from "react-alert";
import { Redirect } from "react-router-dom";
import { useAuth, useFormInput } from "../hooks";

export const Login = () => {
  const { isSignedIn, performSignIn } = useAuth();
  const alert = useAlert();

  const [email, onEmailChange] = useFormInput();
  const [password, onPasswordChange] = useFormInput();

  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const onSubmit = React.useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      setIsLoggingIn(true);

      try {
        await performSignIn({ email, password });
      } catch (e) {
        console.error(e);
        alert.error(`Error while logging in ${e.toString()}`);
      }

      setIsLoggingIn(false);
    },
    [alert, email, password, performSignIn]
  );

  if (isSignedIn) {
    return <Redirect to="/admin" />;
  }

  return (
    <div className="adminPage">
      <h1>Copy Pics</h1>
      <p>Please sign-in:</p>

      <form onSubmit={onSubmit}>
        <div className="formGroup">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={onEmailChange}
          />
        </div>

        <div className="formGroup">
          <label htmlFor="password">Password</label>
          <input
            name="password"
            type="password"
            value={password}
            onChange={onPasswordChange}
          />
        </div>

        <button type="submit" disabled={isLoggingIn}>
          {isLoggingIn ? "Logging in..." : "Login"}
        </button>
      </form>
    </div>
  );
};
