import fb from "firebase";
import React from "react";
import { useAlert } from "react-alert";
import { firebase } from "../../data/firebase";
import { AuthContext } from "./AuthContext";

export const AuthProvider: React.FunctionComponent = ({ children }) => {
  const alert = useAlert();
  /**
   * Method to perform a sign in
   */
  const performSignIn = React.useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      return !!result.user;
    },
    []
  );

  /**
   * Method to perform a sign out
   */
  const performSignOut = React.useCallback(async () => {
    await firebase.auth().signOut();
  }, []);

  /**
   * Subscrube to authentication updates to listen for changes to the uid and admin flag
   * */
  const [user, setUser] = React.useState<fb.User | null | undefined>();
  React.useEffect(() => {
    const authStateChangeUnsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (newUser) => {
        if (!newUser) {
          setUser(null);
        } else {
          const token = await newUser.getIdTokenResult();
          const isAdmin = !!token.claims.admin;
          if (isAdmin) {
            setUser(newUser);
          } else {
            alert.info(
              "The user you signed in with is not an admin. You can't currently sign in with this user"
            );
            setUser(null);
            await performSignOut();
          }
        }
      });

    return authStateChangeUnsubscribe;
  }, [alert, performSignOut]);

  return (
    <AuthContext.Provider
      value={{ user, isSignedIn: !!user, performSignIn, performSignOut }}
    >
      {user !== undefined ? children : null}
    </AuthContext.Provider>
  );
};
