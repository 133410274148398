import { Switch, Route, useRouteMatch } from "react-router-dom";
import {
  useSequence,
  useSequenceIdParam,
  useSequenceImagesLastCreated,
} from "../../hooks";
import { GoogleStorageImage } from "../../components";
import logo from "../../images/logo.svg";
import { AddToSequence } from "./AddToSequence";
import { ViewSequence } from "./ViewSequence";
import { Footer } from "../../components/Footer";

export const Sequence = () => {
  const match = useRouteMatch();

  const sequenceId = useSequenceIdParam();
  const sequence = useSequence(sequenceId);
  const latestSequestImage = useSequenceImagesLastCreated(sequenceId);
  const awaitingVideoGeneration =
    !!sequence &&
    !!latestSequestImage &&
    sequence.videoCreated !== latestSequestImage;

  if (sequence === null) {
    return <p>Not found</p>;
  } else if (sequence === undefined) {
    return <p>Loading</p>;
  }

  return (
    <div
      className="sequencePage"
      style={{
        backgroundColor: sequence.backgroundColor,
        color: sequence.textColor,
      }}
    >
      <div className="content">
        <h1 id="sequenceLogo">
          {sequence.logo ? (
            <GoogleStorageImage
              src={sequence.logo}
              alt="Copy Pics"
              style={{ height: sequence.logoHeight }}
            />
          ) : (
            <img
              src={logo}
              alt="Copy Pics"
              style={{ height: sequence.logoHeight }}
            />
          )}
        </h1>

        <Switch>
          <Route path={`${match.path}/add`}>
            <AddToSequence sequence={sequence} />
          </Route>
          <Route path={`${match.path}/`}>
            <ViewSequence
              sequence={sequence}
              awaitingVideoGeneration={awaitingVideoGeneration}
            />
          </Route>
        </Switch>

        <p id="sequenceDescription">{sequence.description}</p>

        <Footer />
      </div>
    </div>
  );
};
