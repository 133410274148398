import { Switch, Route, useRouteMatch } from "react-router-dom";
import { AdminIndex } from "./AdminIndex";
import { AdminSequenceDetails } from "./AdminSequenceDetails";
import { AdminListSequences } from "./AdminListSequences";
import { AdminSequenceBrandImages } from "./AdminSequenceBrandImages";
import { AdminSequenceColors } from "./AdminSequenceColors";
import { AdminSequenceIndex } from "./AdminSequenceIndex";
import { AdminSequenceTiming } from "./AdminSequenceTiming";
import { AdminSequenceImages } from "./AdminSequenceImages";
import { CreateSequence } from "./CreateSequence";
import { AssignAdmin } from "./AssignAdmin";

export const Admin = () => {
  const match = useRouteMatch();

  return (
    <div className="adminPage">
      <Switch>
        <Route path={`${match.path}/sequences/:sequenceId/brandImages`}>
          <AdminSequenceBrandImages />
        </Route>
        <Route path={`${match.path}/sequences/:sequenceId/colors`}>
          <AdminSequenceColors />
        </Route>
        <Route path={`${match.path}/sequences/:sequenceId/details`}>
          <AdminSequenceDetails />
        </Route>
        <Route path={`${match.path}/sequences/:sequenceId/images`}>
          <AdminSequenceImages />
        </Route>
        <Route path={`${match.path}/sequences/:sequenceId/timing`}>
          <AdminSequenceTiming />
        </Route>
        <Route path={`${match.path}/sequences/:sequenceId`}>
          <AdminSequenceIndex />
        </Route>
        <Route path={`${match.path}/sequences`}>
          <AdminListSequences />
        </Route>
        <Route path={`${match.path}/create`}>
          <CreateSequence />
        </Route>
        <Route path={`${match.path}/assign`}>
          <AssignAdmin />
        </Route>
        <Route path={`${match.path}/`}>
          <AdminIndex />
        </Route>
      </Switch>
    </div>
  );
};
