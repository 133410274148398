import firebaseInternal from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

firebaseInternal.initializeApp({
  apiKey: "AIzaSyCgn23T6zgLco6cQ8z6997x3MHOBklSr3E",
  authDomain: "copy-pics.firebaseapp.com",
  projectId: "copy-pics",
  storageBucket: "copy-pics.appspot.com",
  messagingSenderId: "593234965762",
  appId: "1:593234965762:web:26ee7109b2e84e658a3e1b",
});

if (
  process.env.REACT_APP_DEV === "true" ||
  window.location.hostname === "localhost"
) {
  // firebaseInternal.auth().useEmulator("http://localhost:9099");
  firebaseInternal.functions().useEmulator("localhost", 5001);
  firebaseInternal.firestore().useEmulator("localhost", 8080);
}

export const firebase = firebaseInternal;
