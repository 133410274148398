import React from "react";
import { firebase } from "../data/firebase";
import { SequenceId, SequenceImage, sequenceImageConvertor } from "../types";

export const useSequenceImages = (
  sequenceId: SequenceId
): SequenceImage[] | null | undefined => {
  const [sequenceImages, setSequenceImages] = React.useState<
    SequenceImage[] | null | undefined
  >();

  React.useEffect(
    () =>
      firebase
        .firestore()
        .collection("sequences")
        .doc(sequenceId)
        .collection("images")
        .orderBy("created", "desc")
        .withConverter(sequenceImageConvertor)
        .onSnapshot((snapshot) => {
          setSequenceImages(snapshot.docs.map((doc) => doc.data()));
        }),
    [sequenceId]
  );

  return sequenceImages;
};
