export enum WebcamStreamState {
  Inactive,
  Active,
  Error,
}

export enum WebcamStreamErrorCode {
  Permission,
  InUse,
  NoCamera,
  Unknown,
}

export interface WebcamStreamError {
  code: WebcamStreamErrorCode;
  details: string;
}

export type WebcamStream =
  | { state: WebcamStreamState.Inactive }
  | {
      state: WebcamStreamState.Active;
      stream: MediaStream;
      width: number;
      height: number;
      userFacing: boolean;
    }
  | { state: WebcamStreamState.Error; error: WebcamStreamError };

export type WebcamSnapshot = string;
