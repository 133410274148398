import React from "react";
import { Link } from "react-router-dom";
import { useSequence, useSequenceIdParam } from "../../hooks";

export const AdminSequenceIndex = () => {
  const sequenceId = useSequenceIdParam();
  const sequence = useSequence(sequenceId);

  return (
    <>
      <h1>{sequence?.name}</h1>
      <p>
        <Link to={`/u/${sequenceId}`}>View public sequence</Link>
      </p>
      <p>
        <Link to={`/admin/sequences/${sequenceId}/images`}>
          View and moderate sequence images
        </Link>
      </p>
      <p>
        <Link to={`/admin/sequences/${sequenceId}/details`}>Edit details</Link>
      </p>
      <p>
        <Link to={`/admin/sequences/${sequenceId}/timing`}>
          Edit video timing
        </Link>
      </p>
      <p>
        <Link to={`/admin/sequences/${sequenceId}/colors`}>
          Edit brand colours
        </Link>
      </p>
      <p>
        <Link to={`/admin/sequences/${sequenceId}/brandImages`}>
          Edit brand images
        </Link>
      </p>
      <p>
        <a
          href={`https://console.firebase.google.com/u/0/project/copy-pics/storage/copy-pics.appspot.com/files~2Fsequences~2F${sequenceId}`}
          target="_blank"
          rel="noreferrer"
        >
          Firebase Storage
        </a>
      </p>
      <p>
        <a
          href={`https://console.firebase.google.com/u/0/project/copy-pics/firestore/data~2Fsequences~2F${sequenceId}`}
          target="_blank"
          rel="noreferrer"
        >
          Firebase Firestore Data
        </a>
      </p>
    </>
  );
};
