import { v4 as uuid } from "uuid";
import { firebase } from "./firebase";
import {
  Sequence,
  SequenceId,
  sequenceConvertor,
  sequenceImageConvertor,
  WebcamSnapshot,
  SequenceImageId,
} from "../types";

export const updateSequence = (sequence: Sequence) =>
  firebase
    .firestore()
    .collection("sequences")
    .doc(sequence.id)
    .withConverter(sequenceConvertor)
    .set(sequence);

export const addSequenceImage = async ({
  sequenceId,
  snapshot,
}: {
  sequenceId: SequenceId;
  snapshot: WebcamSnapshot;
}) => {
  const snapshotImageId = uuid();
  const storageRef = firebase
    .storage()
    .ref(`/sequences/${sequenceId}/images/${snapshotImageId}`);
  const snapshotFrameRef = storageRef.child(`${snapshotImageId}.jpg`);
  const uploadSnapshot = await snapshotFrameRef.putString(snapshot, "data_url");
  const location = uploadSnapshot.ref.toString();

  return firebase
    .firestore()
    .collection("sequences")
    .doc(sequenceId)
    .collection("images")
    .withConverter(sequenceImageConvertor)
    .add({
      id: snapshotImageId,
      location,
      created: Date.now(),
    });
};

export const deleteSequenceImage = async ({
  sequenceId,
  sequenceImageId,
}: {
  sequenceId: SequenceId;
  sequenceImageId: SequenceImageId;
}) =>
  firebase
    .firestore()
    .collection("sequences")
    .doc(sequenceId)
    .collection("images")
    .doc(sequenceImageId)
    .delete();
