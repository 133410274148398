import React from "react";
import { GoogleStorageImage } from "../../components";
import { deleteSequenceImage } from "../../data";
import { useSequenceIdParam, useSequenceImages } from "../../hooks";
import { SequenceImage } from "../../types";

export const AdminSequenceImages = () => {
  const sequenceId = useSequenceIdParam();
  const sequenceImages = useSequenceImages(sequenceId);

  const makeDeleteImage = React.useCallback(
    (sequenceImage: SequenceImage) => async () => {
      // eslint-disable-next-line no-alert
      const sure = window.confirm(
        "Are you sure you want to delete this image? The image will be deleted and the video will be regenerated"
      );
      if (!sure) {
        return;
      }

      await deleteSequenceImage({
        sequenceId,
        sequenceImageId: sequenceImage.id,
      });
    },
    [sequenceId]
  );

  return (
    <>
      <h1>View and modorate sequence images</h1>
      {sequenceImages === undefined ? (
        <p>Loading...</p>
      ) : (
        <ul className="adminSequenceImages">
          {sequenceImages?.map((sequenceImage) => (
            <li key={sequenceImage.id}>
              <GoogleStorageImage
                className="adminSequenceImage"
                src={sequenceImage.location}
              />
              <p>{new Date(sequenceImage.created).toLocaleString()}</p>
              <button type="button" onClick={makeDeleteImage(sequenceImage)}>
                Delete
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
