import firebase from "firebase";
import _ from "lodash";

export type SequenceId = string;
export interface Sequence {
  id: SequenceId;
  name: string;
  description: string;
  logo: string | null;
  logoHeight: number;
  backgroundColor: string;
  textColor: string;
  sourceImage: string | null;
  overlayImage: string | null;
  video: string | null;
  videoCreated: number | null;
  applyOverlayInVideo: boolean;
  hideSourceImageInVideo: boolean;
  timingSourceImage: number; // milliseconds
  timingImages: number; // milliseconds
}

export const sequenceConvertor: firebase.firestore.FirestoreDataConverter<Sequence> = {
  toFirestore: (modelObject: Sequence) => _.omit(modelObject, "id"),

  fromFirestore: (snapshot) => ({
    id: snapshot.id,
    name: snapshot.get("name"),
    description: snapshot.get("description"),
    logo: snapshot.get("logo") || null,
    logoHeight: snapshot.get("logoHeight"),
    backgroundColor: snapshot.get("backgroundColor"),
    textColor: snapshot.get("textColor"),
    sourceImage: snapshot.get("sourceImage") || null,
    overlayImage: snapshot.get("overlayImage") || null,
    video: snapshot.get("video") || null,
    videoCreated: snapshot.get("videoCreated") || null,
    applyOverlayInVideo: !!snapshot.get("applyOverlayInVideo"),
    hideSourceImageInVideo: !!snapshot.get("hideSourceImageInVideo"),
    timingSourceImage: snapshot.get("timingSourceImage"),
    timingImages: snapshot.get("timingImages"),
  }),
};
