import React, { FormEvent } from "react";
import { useAlert } from "react-alert";
import { firebase } from "../../data/firebase";
import { useFormInput } from "../../hooks";

export const AssignAdmin = () => {
  const alert = useAlert();

  const [uid, onUidChange, setUid] = useFormInput();

  const [isAssigning, setIsAssigning] = React.useState(false);
  const onSubmit = React.useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      setIsAssigning(true);

      try {
        await firebase.functions().httpsCallable("assignAdmin")({ uid });
        alert.success("Assigned admin rights");
        setUid("");
      } catch (e) {
        console.error(e);
        alert.error(`Error while assigning admin rights ${e.toString()}`);
      }

      setIsAssigning(false);
    },
    [alert, setUid, uid]
  );

  return (
    <>
      <h1>Create sequence</h1>

      <form onSubmit={onSubmit}>
        <div className="formGroup">
          <label htmlFor="uid">
            User id (from{" "}
            <a
              href="https://console.firebase.google.com/u/0/project/copy-pics/authentication/users"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            )
          </label>
          <input id="uid" value={uid} onChange={onUidChange} />
        </div>

        <button type="submit" disabled={isAssigning}>
          {isAssigning ? "Assigning..." : "Assign admin rights"}
        </button>
      </form>
    </>
  );
};
