import React from "react";

export interface FormInputParams {
  defaultValue?: string;
  inputTransform?: (input: string) => string;
}

export const useFormInput = ({
  defaultValue,
  inputTransform,
}: FormInputParams = {}): [
  string,
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  (value: string) => void
] => {
  const [value, setValue] = React.useState(defaultValue || "");

  const handleValueChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (inputTransform) {
        setValue(inputTransform(event.target.value));
      } else {
        setValue(event.target.value);
      }
    },
    [inputTransform]
  );

  return [value, handleValueChange, setValue];
};
