import React, { FormEvent } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { updateSequence } from "../../data";
import { useFormInput, useSequence, useSequenceIdParam } from "../../hooks";

export const AdminSequenceColors = () => {
  const history = useHistory();
  const alert = useAlert();

  const sequenceId = useSequenceIdParam();
  const sequence = useSequence(sequenceId);

  const [
    backgroundColor,
    onBackgroundColorChange,
    setBackgroundColor,
  ] = useFormInput();
  const [textColor, onTextColorChange, setTextColor] = useFormInput();
  React.useEffect(() => {
    if (!sequence) {
      return;
    }

    setBackgroundColor(sequence.backgroundColor.toString());
    setTextColor(sequence.textColor.toString());
  }, [sequence, setTextColor, setBackgroundColor]);

  const [isUpdating, setIsUpdating] = React.useState(false);
  const onSubmit = React.useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (!sequence) {
        return;
      }

      if (!backgroundColor || !textColor) {
        alert.error("You did not enter valid colours");
        return;
      }

      setIsUpdating(true);

      try {
        await updateSequence({
          ...sequence,
          backgroundColor,
          textColor,
        });

        alert.success("Updated brand clours");
        history.push(`/admin/sequences/${sequenceId}`);
      } catch (e) {
        console.error(e);
        alert.error(`Error while updating video timing ${e.toString()}`);
      }

      setIsUpdating(false);
    },
    [alert, history, sequence, sequenceId, textColor, backgroundColor]
  );

  if (sequence === undefined) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <h1>Edit brand colours</h1>

      <form onSubmit={onSubmit}>
        <div className="formGroup">
          <label htmlFor="backgroundColor">Background color</label>
          <input
            id="backgroundColor"
            type="color"
            value={backgroundColor}
            onChange={onBackgroundColorChange}
          />
        </div>

        <div className="formGroup">
          <label htmlFor="textColor">Text color</label>
          <input
            name="textColor"
            type="color"
            value={textColor}
            onChange={onTextColorChange}
          />
        </div>

        <button type="submit" disabled={isUpdating}>
          {isUpdating ? "Updating..." : "Update"}
        </button>
      </form>
    </>
  );
};
