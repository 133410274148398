import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
  Redirect,
} from "react-router-dom";
import { useAuth } from "./hooks";
import { Admin } from "./screens/Admin";
import { Home } from "./screens/Home";
import { Login } from "./screens/Login";
import { Logout } from "./screens/Logout";
import { Sequence } from "./screens/Sequence";

const PrivateRoute: React.FunctionComponent<RouteProps> = ({
  children,
  ...rest
}) => {
  const { isSignedIn } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isSignedIn ? (
          children
        ) : (
          <Redirect to={`/login?continue=${location.pathname}`} />
        )
      }
    />
  );
};

export const App = () => (
  <Router>
    <Switch>
      <PrivateRoute path="/admin">
        <Admin />
      </PrivateRoute>
      <Route path="/u/:sequenceId">
        <Sequence />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  </Router>
);
