import React from "react";
import firebase from "firebase";

export const AuthContext = React.createContext<{
  user: firebase.User | null | undefined;
  isSignedIn: boolean;
  performSignIn: (params: {
    email: string;
    password: string;
  }) => Promise<boolean>;
  performSignOut: () => Promise<void>;
}>({
  user: undefined,
  isSignedIn: false,
  performSignIn: () => {
    throw new Error("Must be called from inside an AuthContext provider");
  },
  performSignOut: () => {
    throw new Error("Must be called from inside an AuthContext provider");
  },
});
