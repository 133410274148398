import React from "react";
import { firebase } from "../data/firebase";
import { Sequence, sequenceConvertor } from "../types";

export const useSequences = (): Sequence[] | null | undefined => {
  const [sequences, setSequences] = React.useState<
    Sequence[] | null | undefined
  >();

  React.useEffect(
    () =>
      firebase
        .firestore()
        .collection("sequences")
        .orderBy("name")
        .withConverter(sequenceConvertor)
        .onSnapshot((snapshot) =>
          setSequences(snapshot.docs.map((doc) => doc.data()))
        ),
    []
  );

  return sequences;
};
