import React from "react";
import "webrtc-adapter";
import {
  WebcamStreamState,
  WebcamStream,
  WebcamStreamErrorCode,
} from "../../types";
import { WebcamStreamContext } from "./WebcamStreamContext";

export const SNAPSHOT_WIDTH = 480;
export const SNAPSHOT_HEIGHT = 480;

export const WebcamStreamProvider: React.FunctionComponent = ({ children }) => {
  // Store the state
  const [stream, setStream] = React.useState<WebcamStream>({
    state: WebcamStreamState.Inactive,
  });
  const streamStateRef = React.useRef(stream.state);
  React.useEffect(() => {
    streamStateRef.current = stream.state;
  }, [stream.state]);
  const streamStreamRef = React.useRef(
    stream.state === WebcamStreamState.Active ? stream.stream : undefined
  );
  React.useEffect(() => {
    streamStreamRef.current =
      stream.state === WebcamStreamState.Active ? stream.stream : undefined;
  }, [stream]);

  // Handle errors
  const handleError = React.useCallback((e: any) => {
    const details = e.message || e.toString();

    let code: WebcamStreamErrorCode;
    if (
      e.name === "NotFoundError" ||
      e.name === "DevicesNotFoundError" ||
      e.name === "OverconstrainedError" ||
      e.name === "ConstraintNotSatisfiedError"
    ) {
      // required track is missing  or constraints can not be satisfied by avb. devices
      code = WebcamStreamErrorCode.NoCamera;
    } else if (e.name === "NotReadableError" || e.name === "TrackStartError") {
      // webcam or mic are already in use
      code = WebcamStreamErrorCode.InUse;
    } else if (
      e.name === "NotAllowedError" ||
      e.name === "PermissionDeniedError"
    ) {
      // permission denied in browser
      code = WebcamStreamErrorCode.Permission;
    } else {
      // other errors
      code = WebcamStreamErrorCode.Unknown;
    }

    setStream({
      state: WebcamStreamState.Error,
      error: { code, details },
    });
  }, []);

  // If we want to use the front or rear camera. This is a ref because inside the `swapCamera`
  // function the `startStream` callback will not have the new value if we use state
  const requestFrontCamera = React.useRef(true);

  // Start the stream
  const startStream = React.useCallback(
    async (force: boolean = false) => {
      try {
        if (!force && streamStateRef.current === WebcamStreamState.Active) {
          // Nothing to do if it's already active
          return;
        }

        const newStream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: requestFrontCamera.current ? "user" : "environment",
            width: SNAPSHOT_WIDTH,
            height: SNAPSHOT_HEIGHT,
          },
          audio: false,
        });

        if (newStream.getVideoTracks().length === 0) {
          // TODO: Handle error
          handleError("No video track from the webcam");
          return;
        }

        const {
          width,
          height,
          facingMode,
        } = newStream.getVideoTracks()[0].getSettings();
        if (!width || !height) {
          // TODO: Handle error
          handleError("Unable to get size of webcam video");
          return;
        }

        setStream({
          state: WebcamStreamState.Active,
          stream: newStream,
          width,
          height,
          userFacing: facingMode === "user",
        });
      } catch (e) {
        handleError(e);
      }
    },
    [handleError]
  );

  // Stop the stream
  const stopStream = React.useCallback(async () => {
    try {
      if (!streamStreamRef.current) {
        // Nothing to do if it's not active
        return;
      }

      streamStreamRef.current.getTracks().forEach((track) => track.stop());

      setStream({ state: WebcamStreamState.Inactive });
    } catch (e) {
      handleError(e);
    }
  }, [handleError]);

  // Always stop stream when we are unmounted
  React.useEffect(
    () => () => {
      stopStream();
    },
    [stopStream]
  );

  // Swap cameras and
  const swapCamera = React.useCallback(async () => {
    requestFrontCamera.current = !requestFrontCamera.current;
    await startStream(true);
  }, [startStream]);

  // Handle the snapshot canvas and creating snapshots
  const snapshotCanvasRef = React.createRef<HTMLCanvasElement>();
  const createSnapshot = React.useCallback(
    async (videoEl: HTMLVideoElement) => {
      if (
        !snapshotCanvasRef.current ||
        stream.state !== WebcamStreamState.Active
      ) {
        return;
      }

      const ctx = snapshotCanvasRef.current.getContext("2d");
      if (!ctx) {
        return;
      }

      ctx.drawImage(videoEl, 0, 0, SNAPSHOT_WIDTH, SNAPSHOT_HEIGHT);

      return snapshotCanvasRef.current.toDataURL("image/jpeg", 0.9);
    },
    [snapshotCanvasRef, stream]
  );

  return (
    <WebcamStreamContext.Provider
      value={{
        stream,
        startStream,
        stopStream,
        swapCamera,
        createSnapshot,
      }}
    >
      {stream.state === WebcamStreamState.Active && (
        <canvas
          ref={snapshotCanvasRef}
          className="is-hidden"
          width={SNAPSHOT_WIDTH}
          height={SNAPSHOT_HEIGHT}
        />
      )}
      {children}
    </WebcamStreamContext.Provider>
  );
};
