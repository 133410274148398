import React, { FormEvent } from "react";
import { useAlert } from "react-alert";
import { GoogleStorageImage } from "../../components";
import { updateSequence } from "../../data";
import { firebase } from "../../data/firebase";
import { useFormFileInput, useSequence, useSequenceIdParam } from "../../hooks";

export const AdminSequenceBrandImages = () => {
  const alert = useAlert();

  const sequenceId = useSequenceIdParam();
  const sequence = useSequence(sequenceId);

  const [logoFile, onLogoFileChange, setLogoFile] = useFormFileInput({
    mimeTypePrefix: "image/",
  });
  const [isUpdatingLogo, setIsUpdatingLogo] = React.useState(false);

  const [
    sourceImageFile,
    onSourceImageFileChange,
    setSourceImageFile,
  ] = useFormFileInput({
    mimeTypePrefix: "image/",
  });
  const [isUpdatingSourceImage, setIsUpdatingSourceImage] = React.useState(
    false
  );

  const [
    overlayImageFile,
    onOverlayImageFileChange,
    setOverlayImageFile,
  ] = useFormFileInput({
    mimeTypePrefix: "image/",
  });
  const [isUpdatingOverlayImage, setIsUpdatingOverlayImage] = React.useState(
    false
  );

  const makeOnSubmit = React.useCallback(
    (
      type: string,
      file: File | undefined,
      setLoadingState: (state: boolean) => void,
      setFile: (file: File | undefined) => void
    ) => async (event: FormEvent) => {
      event.preventDefault();

      if (!sequence) {
        return;
      }

      setLoadingState(true);

      if (file) {
        try {
          const nameParts = file.name.split(".");
          const ext = nameParts[nameParts.length - 1];

          const uploadedFile = await firebase
            .storage()
            .ref(`/sequences/${sequence.id}`)
            .child(`${type}.${ext}`)
            .put(file);

          await updateSequence({
            ...sequence,
            [type]: uploadedFile.ref.toString(),
          });

          alert.success(`Updated ${type}`);
          setFile(undefined);
        } catch (e) {
          console.error(e);
          alert.error(`Error while updating ${type} ${e.toString()}`);
        }
      } else {
        try {
          await updateSequence({
            ...sequence,
            [type]: "",
          });

          alert.success(`Removed ${type}`);
        } catch (e) {
          console.error(e);
          alert.error(`Error while removing ${type} ${e.toString()}`);
        }
      }
      setLoadingState(false);
    },
    [sequence, alert]
  );

  if (sequence === undefined) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <h1>Edit brand images</h1>

      <h2>Logo</h2>
      <form
        onSubmit={makeOnSubmit(
          "logo",
          logoFile,
          setIsUpdatingLogo,
          setLogoFile
        )}
      >
        <div>
          <GoogleStorageImage
            src={sequence?.logo || undefined}
            style={{ height: sequence?.logoHeight, marginBottom: "8px" }}
          />
        </div>
        <div>
          <input id="logoFile" type="file" onChange={onLogoFileChange} />
        </div>

        <button type="submit" disabled={isUpdatingLogo}>
          {isUpdatingLogo
            ? "Updating..."
            : `${logoFile ? "Update" : "Remove"} Logo`}
        </button>
      </form>

      <h2>Source Image</h2>
      <form
        onSubmit={makeOnSubmit(
          "sourceImage",
          sourceImageFile,
          setIsUpdatingSourceImage,
          setSourceImageFile
        )}
      >
        <div>
          <GoogleStorageImage
            src={sequence?.sourceImage || undefined}
            style={{ marginBottom: "8px" }}
          />
        </div>
        <div>
          <input
            id="sourceImageFile"
            type="file"
            onChange={onSourceImageFileChange}
          />
        </div>

        <button type="submit" disabled={isUpdatingSourceImage}>
          {isUpdatingSourceImage
            ? "Updating..."
            : `${sourceImageFile ? "Update" : "Remove"} Source Image`}
        </button>
      </form>

      <h2>Overlay Image</h2>
      <form
        onSubmit={makeOnSubmit(
          "overlayImage",
          overlayImageFile,
          setIsUpdatingOverlayImage,
          setOverlayImageFile
        )}
      >
        <div>
          <GoogleStorageImage
            src={sequence?.overlayImage || undefined}
            style={{ marginBottom: "8px" }}
          />
        </div>
        <div>
          <input
            id="overlayImageFile"
            type="file"
            onChange={onOverlayImageFileChange}
          />
        </div>

        <button type="submit" disabled={isUpdatingOverlayImage}>
          {isUpdatingOverlayImage
            ? "Updating..."
            : `${overlayImageFile ? "Update" : "Remove"} Overlay Image`}
        </button>
      </form>
    </>
  );
};
