import React from "react";
import { Link } from "react-router-dom";
import { GoogleStorageVideo } from "../../components";
import { Sequence } from "../../types";
import addPhoto from "../../images/add_photo.svg";
import share from "../../images/share.svg";

export interface ViewSequenceProps {
  sequence: Sequence;
  awaitingVideoGeneration: boolean;
}

export const ViewSequence: React.FC<ViewSequenceProps> = ({
  sequence,
  awaitingVideoGeneration,
}) => (
  <>
    {sequence.video ? (
      <div id="videoDisplay" className="aspectContainer">
        <GoogleStorageVideo
          src={sequence.video}
          autoPlay
          controls={false}
          muted
          loop
        />
        {awaitingVideoGeneration && (
          <div id="generatingOverlay">
            <p>Regenerating...</p>
          </div>
        )}
      </div>
    ) : (
      <p id="noImages">No images in the sequence yet. Why not add one?</p>
    )}

    <div id="sequenceButtons">
      <Link to={`/u/${sequence.id}/add`}>
        <img src={addPhoto} alt="Add photo" />
      </Link>

      {sequence.video && (
        <a href={`/downloadFile?path=${sequence.video}`} download>
          <img src={share} alt="Download video" />
        </a>
      )}
    </div>
  </>
);
