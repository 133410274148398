import { Link } from "react-router-dom";

export const AdminIndex = () => (
  <>
    <h1>Admin</h1>
    <p>
      <Link to="/admin/create">Create Sequence</Link>
    </p>
    <p>
      <Link to="/admin/sequences">View Sequences</Link>
    </p>
    <p>
      <Link to="/admin/assign">Assign new admin user</Link>
    </p>
    <p>
      <Link to="/logout">Logout</Link>
    </p>
  </>
);
