import React, { FormEvent } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { updateSequence } from "../../data";
import { useFormInput, useSequence, useSequenceIdParam } from "../../hooks";

export const AdminSequenceDetails = () => {
  const history = useHistory();
  const alert = useAlert();
  const sequenceId = useSequenceIdParam();
  const sequence = useSequence(sequenceId);

  const [sequenceName, onSequenceNameChange, setSequenceName] = useFormInput();
  const [description, onDescriptionChange, setDescription] = useFormInput();
  React.useEffect(() => {
    if (!sequence) {
      return;
    }

    setSequenceName(sequence.name);
    setDescription(sequence.description);
  }, [sequence, setDescription, setSequenceName]);

  const [isUpdating, setIsUpdating] = React.useState(false);
  const onSubmit = React.useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (!sequence) {
        return;
      }

      if (sequenceName.length < 4) {
        alert.error("Invalid sequence name. Must be at least 3 characters");
        return;
      }

      setIsUpdating(true);

      try {
        await updateSequence({
          ...sequence,
          name: sequenceName,
          description,
        });

        alert.success("Sequence details updated");
        history.push(`/admin/sequences/${sequenceId}`);
      } catch (e) {
        console.error(e);
        alert.error(`Error while updating sequence details ${e.toString()}`);
      }

      setIsUpdating(false);
    },
    [alert, description, history, sequence, sequenceId, sequenceName]
  );

  return (
    <>
      <h1>Edit sequence details</h1>

      <form onSubmit={onSubmit}>
        <div className="formGroup">
          <label htmlFor="sequenceName">Name</label>
          <input
            name="sequenceName"
            value={sequenceName}
            onChange={onSequenceNameChange}
          />
        </div>

        <div className="formGroup">
          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            value={description}
            onChange={onDescriptionChange}
          />
        </div>

        <button type="submit" disabled={isUpdating}>
          {isUpdating ? "Updating..." : "Update"}
        </button>
      </form>
    </>
  );
};
