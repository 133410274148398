import React from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { CaptureSnapshot } from "./CaptureSnapshot";
import { ReviewSnapshot } from "./ReviewSnapshot";
import { useWebcamStream } from "../../../hooks";
import { Sequence, WebcamSnapshot } from "../../../types";
import { addSequenceImage } from "../../../data";

export interface AddToSequenceProps {
  sequence: Sequence;
}

export const AddToSequence: React.FC<AddToSequenceProps> = ({ sequence }) => {
  const alert = useAlert();
  const history = useHistory();
  const { startStream, stopStream } = useWebcamStream();

  // Stop the webcam when navigating away
  React.useEffect(() => {
    startStream();

    return () => stopStream();
  }, [startStream, stopStream]);

  const [snapshot, setSnapshot] = React.useState<WebcamSnapshot>();

  const onAcceptSnapshot = React.useCallback(async () => {
    if (!snapshot) {
      return;
    }

    try {
      await addSequenceImage({ sequenceId: sequence.id, snapshot });
      history.push(`/u/${sequence.id}`);
    } catch (e) {
      console.error(e);
      alert.error(`Error while adding sequence image ${e.toString()}`);
    }
  }, [alert, history, sequence.id, snapshot]);

  const content = React.useMemo(() => {
    if (snapshot) {
      return (
        <ReviewSnapshot
          snapshot={snapshot}
          onAccept={onAcceptSnapshot}
          onReject={() => setSnapshot(undefined)}
        />
      );
    } else {
      return (
        <CaptureSnapshot
          sourceImage={sequence.sourceImage}
          onCapture={setSnapshot}
        />
      );
    }
  }, [onAcceptSnapshot, sequence.sourceImage, snapshot]);

  return content;
};
