import React from "react";
import { WebcamSnapshot } from "../types";

export interface SnapshotDisplayProps {
  snapshot: WebcamSnapshot;
}

export const SnapshotDisplay: React.FC<SnapshotDisplayProps> = ({
  snapshot,
}) => (
  <div className="aspectContainer">
    <img src={snapshot} alt="" />
  </div>
);
